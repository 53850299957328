import { Flex } from '@iheartradio/web.accomplice/flex';
import { Box, Link, Text } from '@iheartradio/web.companion';
import { Link as RouterLink } from '@remix-run/react';

import { useConfig } from '~app/contexts/config';

import { DevicesGraphic } from './devices-graphic';

export function DownloadTheAppRow() {
  const config = useConfig();

  return (
    <Flex
      alignItems="center"
      display={{ mobile: 'none', large: 'flex' }}
      gap="$24"
      justifyContent="space-between"
      padding={{ mobile: '0 $16', large: '$32 $32' }}
    >
      <Flex direction="column" gap="$8">
        <Text kind="h3">Bring iHeart with you anywhere</Text>
        <Text kind="body-3">
          The free iHeart app is available on over 200 platforms, including Web,{' '}
          <Link
            as={RouterLink}
            color="secondary"
            target="_blank"
            to={config.urls.appsMobile!}
            underline="always"
          >
            iOS
          </Link>
          ,{' '}
          <Link
            as={RouterLink}
            color="secondary"
            target="_blank"
            to={config.urls.appsMobile!}
            underline="always"
          >
            Android
          </Link>
          ,{' '}
          <Link
            as={RouterLink}
            color="secondary"
            target="_blank"
            to={config.urls.appsHome!}
            underline="always"
          >
            Alexa
          </Link>
          ,{' '}
          <Link
            as={RouterLink}
            color="secondary"
            target="_blank"
            to={config.urls.appsAuto!}
            underline="always"
          >
            Automobiles
          </Link>
          , and more.
        </Text>
      </Flex>
      <Box
        height="auto"
        maxHeight={{ '@initial': '30rem', '@xlarge': 'none' }}
        maxWidth="94rem"
        minWidth={{ '@initial': '$7', '@xlarge': '$6' }}
        width="100%"
      >
        <DevicesGraphic />
      </Box>
    </Flex>
  );
}
